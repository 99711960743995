import React from 'react';
import { Grid } from '@material-ui/core';
import Typography from '../../components/Typography';
import styles from './styles.module.sass';

export default ({ text }) => (
  <Grid container item xs={12} sm={4} md={4} lg={4} xl={4}
    className={styles.checkContainer}
    alignContent='center'
    alignItems='center'>
    <div className={styles.checkIconContainer}>
      <svg xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 30.895 24.278">
        <path d="M-5832.061,8783.008l8.924,9.355,19.076-20" transform="translate(5833.508 -8770.982)" fill="none" stroke="#fff" strokeWidth="4"/>
      </svg>
    </div>
    <Typography variant='subtitle2' weight='regular'
      color='dark'
      className={styles.checkText}>
      {text}
    </Typography>
  </Grid>
)