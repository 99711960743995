import React from 'react';
import 'whatwg-fetch';
import { Grid } from '@material-ui/core';
import i18n from '../../locale';
import Typography from '../../components/Typography';
import SectionWrapper from '../../components/SectionWrapper';
import Button from '../../components/Button';
import Check from './Check';
import ErrorMessage from './ErrorMessage';
import styles from './styles.module.sass';

const postToForm = email => fetch(
'https://api.hsforms.com/submissions/v3/integration/submit/5142926/856c0f88-d86c-464d-80c1-a3bf3727f269',
{
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    'submittedAt': Date.now(),
    'fields': [{
        'name' : 'email',
        'value': email
      }
    ],
    'context': {
      'pageUri': i18n('_url:_'),
      'pageName': 'Call to action sign-up - Traffilog Nordic'
    },
    'legalConsentOptions': {
      "consent": {
        "consentToProcess": true,
        "text": "I agree to allow Traffilog Nordic to store and process my personal data.",
        "communications": [
          {
            "value": true,
            "subscriptionTypeId": 999,
            "text": "I agree to receive marketing communications from Traffilog Nordic."
          }
        ]
      }
    }
  })
})

export default () => {
  const [ submitted, setSubmitted ] = React.useState(false);
  const [ email, setEmail ] = React.useState('');
  const [ error, setError ] = React.useState(false);

  return (

    <SectionWrapper className={styles.container}>
      {!submitted
      ? <Grid item xs={12} md={12} className={styles.overviewContainer}>
          <Typography
            variant='h3'
            color='dark'
            weight='bold'
            align='center'
            className={styles.title}>
            {i18n('Ready to explore further?')}
          </Typography>   
          <div className={styles.emailContainer}>
            <div className={styles.inputContainer}>
              <input type='email' className={styles.input}
                defaultValue=''
                onChange={e => setEmail(e.target.value)}
                placeholder={i18n('Email address')}/>
              <Grid container className={styles.checksContainerWide}>
                <Check text={i18n('Intro call')} xs={4} sm={4} md={4} lg={4} xl={4}/>
                <Check text={i18n('Consultation')}/>
                <Check text={i18n('Product tour')}/>
              </Grid>
            </div>
            <div className={styles.buttonContainer}>
              <Button className={styles.button}
                onClick={() => postToForm(email)
                  .then(res => res.json()).then(res => {
                    if(res.status !== 'error') {
                      setError(false);
                      setSubmitted(true);
                    } else setError(true);
                  }).catch(() => setError(true))}>
                <Typography variant='subtitle1'
                  weight='bold'
                  color='white'
                  align='center'>
                  {i18n("Let's connect")}
                </Typography>
              </Button>
            </div>
            <Grid container className={styles.checksContainerNarrow}>
              <Check text={i18n('Product tour')} xs={4} sm={4} md={4} lg={4} xl={4}/>
              <Check text={i18n('Consultation')}/>
              <Check text={i18n('Trial')}/>
            </Grid>
          </div>
          <ErrorMessage error={error}/>
        </Grid>
      : <Grid item xs={12} md={12} className={styles.overviewContainer}>
          <Typography
            variant='h3'
            color='dark'
            weight='bold'
            align='center'
            className={styles.thankYou}>
            {i18n('Perfect! We will contact you soon.')}
          </Typography>
        </Grid>}
    </SectionWrapper>
  )
};
